@import "../../../../scss/settings/variable";

.nb-lazy-load-fallback {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nb-card-loader {
  min-height: 0;
  margin: 3rem 0;
}

@import "../settings/colors";
@import "./../settings/global";
$sizes: 100;
$widthLimit: 1000;

$break-points: (
  // xxs
  screen-xxs-max: 375px,
  //xs
  screen-xs-min: 374.98px,
  screen-xs-max: 576px,
  //sm
  screen-sm-min: 576px,
  screen-sm-max: 767.98px,
  //md
  screen-md-min: 768px,
  screen-md-max: 991.98px,
  //lg
  screen-lg-min: 992px,
  screen-lg-max: 1199.98px,
  //xl
  screen-xl-min: 1200px,
  screen-xl-max: 1599.98px,
  //xxl
  screen-xxl-min: 1600px,
  screen-xxl-max: 1920.98px,
  // xxxl
  screen-xxxl-min: 1921px
);

@mixin width-classes {
  @for $i from 0 through $widthLimit {
    $width: $i * 1px;
    .mxw#{$i} {
      max-width: $width;
    }
    .mnw#{$i} {
      min-width: $width;
    }
    .w#{$i} {
      width: $width;
    }
    .w-p#{$i} {
      width: $i * 1%;
    }
  }
  .w-fc {
    width: fit-content;
  }
}

@mixin height-classes {
  @for $i from 0 through $widthLimit {
    $height: $i * 1px;
    .mxh#{$i} {
      max-height: $height;
    }
    .mnh#{$i} {
      min-height: $height;
    }
    .h#{$i} {
      height: $height;
    }
    .h-p#{$i} {
      height: $i * 1%;
    }
    .h-rem#{$i} {
      height: $i * 0.25rem;
    }
  }
}

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .ma {
    margin: auto;
  }
  .ml-auto {
    margin-left: auto;
  }
}

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding !important;
    }
    .pl#{$i} {
      padding-left: $padding !important;
    }
    .pr#{$i} {
      padding-right: $padding !important;
    }
    .pt#{$i} {
      padding-top: $padding !important;
    }
    .pb#{$i} {
      padding-bottom: $padding !important;
    }
    .px#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding !important;
    }
  }
}

@mixin border-classes {
  @for $i from 1 through $sizes {
    $borderRadius: $i * 0.25rem;
    .br#{$i} {
      border-radius: $borderRadius;
    }
    .br-p#{$i} {
      border-radius: $i * 1%;
    }
    .br-tl#{$i} {
      border-top-left-radius: $borderRadius;
    }
    .br-tr#{$i} {
      border-top-right-radius: $borderRadius;
    }
    .br-bl#{$i} {
      border-bottom-left-radius: $borderRadius;
    }
    .br-br#{$i} {
      border-bottom-right-radius: $borderRadius;
    }
    .br-t#{$i} {
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }
    .br-b#{$i} {
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }
}

@mixin font-size($font-size, $line-height: auto) {
  font-size: $font-size;
  font-size: ($font-size / $base-font-size) * 1rem;

  @if $line-height == auto {
    line-height: ceil($font-size / $base-line-height) *
      ($base-line-height / $font-size);
  } @else {
    @if (
      type-of($line-height) ==
        number or
        $line-height ==
        inherit or
        $line-height ==
        normal
    ) {
      line-height: $line-height;
    } @else if ($line-height != none and $line-height != false) {
      @warn "D’oh! ‘#{$line-height}’ is not a valid value for `line-height`.";
    }
  }
}

// Extra small devices less than 375px)

@mixin xxs {
  @media (max-width: map-get($break-points, screen-xxs-max)) {
    @content;
  }
}

// Extra small devices (portrait phones, less than 576px)
@mixin xs {
  @media (max-width: map-get($break-points, screen-xs-max)) {
    @content;
  }
}

// Small devices (landscape phones, 576px and 767.98px)
@mixin sm {
  @media (min-width: map-get($break-points, screen-sm-min)) and (max-width: map-get($break-points, screen-sm-max)) {
    @content;
  }
}

// Medium devices (tablets, 768px and 991.98px)
@mixin md {
  @media (min-width: map-get($break-points, screen-md-min)) and (max-width: map-get($break-points, screen-md-max)) {
    @content;
  }
}

// Large devices (desktops, 992px and up)
@mixin lg {
  @media (min-width: map-get($break-points, screen-lg-min)) and (max-width: map-get($break-points, screen-lg-max)) {
    @content;
  }
}

// Extra large devices (large desktops, 1200px and 1599.98px)
@mixin xl {
  @media (min-width: map-get($break-points, screen-xl-min)) and (max-width: map-get($break-points, screen-xl-max)) {
    @content;
  }
}
// Extra large devices (large desktops, 1600px and up)

@mixin xxl {
  @media (min-width: map-get($break-points, screen-xxl-min)) {
    @content;
  }
}

// Extra large devices (large desktops, 1920px and up)

@mixin xxxl {
  @media (min-width: map-get($break-points, screen-xxxl-min)) {
    @content;
  }
}

@mixin sm-max-below {
  @media (max-width: map-get($break-points, screen-sm-max)) {
    @content;
  }
}
@mixin xs-max-below {
  @media (max-width: map-get($break-points, screen-xs-max)) {
    @content;
  }
}

@mixin md-max-below {
  @media (max-width: map-get($break-points, screen-md-max)) {
    @content;
  }
}

@mixin lg-max-below {
  @media (max-width: map-get($break-points, screen-lg-max)) {
    @content;
  }
}

@mixin xl-max-below {
  @media (max-width: map-get($break-points, screen-xl-max)) {
    @content;
  }
}

@mixin xl-min-below {
  @media (max-width: map-get($break-points, screen-xl-min)) {
    @content;
  }
}

// Custom devices for width and height
@mixin rwd($width, $height) {
  @media (width: $width+"px") and (height: $height+"px") {
    @content;
  }
}

// Custom devices for width
@mixin rw($width) {
  @media (width: $width+"px") {
    @content;
  }
}

// Custom Range for inconvenient sizes
@mixin customRange($min, $max) {
  @media (min-width: $min+"px") and (max-width: $max+"px") {
    @content;
  }
}

//custom min-width and min-height
@mixin customMaxHeightWidth($maxWidth, $maxHeight) {
  @media (max-width: $maxWidth+"px") and (max-height: $maxHeight+"px") {
    @content;
  }
}

@mixin nbTextInput {
  min-height: 60px;
  border-radius: 0.3rem !important;
  padding-left: 1.5rem;
  border: none;
  box-shadow: 0px 3.200000047683716px 32px 0px rgba(45, 45, 46, 0.08);
  &:focus {
    border: none !important;
    box-shadow: 0px 3.200000047683716px 32px 0px rgba(45, 45, 46, 0.08);
  }
}

@mixin nbSelectInput {
  .ant-select-selector {
    min-height: 60px;
    border-radius: $base-border-radius !important;
    .ant-select-selection-search {
      min-height: inherit;
      .ant-select-selection-search-input {
        min-height: inherit;
      }
    }
    .ant-select-selection-item {
      margin: auto 0;
    }
    .ant-select-selection-placeholder {
      margin: auto 0;
    }
  }
}

@mixin nbButton {
  min-height: 38px;
  border-radius: $base-border-radius !important;
}

@mixin nbPrimaryText {
  color: $primary-color !important;
}

@mixin nbFadedText {
  color: $faded-font-color;
}

@mixin nbBaseText {
  color: $base-font-color;
}

@mixin nbBlueText {
  color: $blue-font-color;
}

@mixin nbPrimaryBackground {
  background: transparent linear-gradient(165deg, #40be33 0%, #41a037 100%) 0%
    0% no-repeat padding-box;
}

.nb-text-input {
  @include nbTextInput;
}

.nb-select-input {
  @include nbSelectInput;
}

.nb-button {
  @include nbButton;

  &-outlined {
    background: white !important;
    border: 2px solid $border-color;
    box-shadow: none;
    color: $base-font-color;

    &:focus,
    &:hover,
    &:active {
      color: $base-font-color;
    }
  }
}

.nb-primary-text {
  @include nbPrimaryText;
}

.nb-faded-text {
  @include nbFadedText;
}

.nb-base-text {
  @include nbBaseText;
}

.nb-white-text {
  color: #ffffff;
}

.nb-danger-text {
  color: $danger-font-color !important;
}

.fn5 {
  font-size: 0.5rem;
}

.fn6 {
  font-size: 0.6rem;
}
.fn7 {
  font-size: 0.7rem;
}
.fn8 {
  font-size: 0.8rem;
}
.fn9 {
  font-size: 0.9rem;
}
.fn10 {
  font-size: 1rem;
}

.c-pointer {
  cursor: pointer;
}

.c-not-allowed {
  cursor: not-allowed;
}
.c-auto {
  cursor: auto;
}

.p-event-none {
  pointer-events: none;
}

.fnw-normal {
  font-weight: normal;
}

.fnw-bold {
  font-weight: bold;
}

.fnw-100 {
  font-weight: 100;
}
.fnw-200 {
  font-weight: 200;
}

.fnw-300 {
  font-weight: 300;
}

.fnw-400 {
  font-weight: 400;
}

.fnw-500 {
  font-weight: 500;
}

.fnw-600 {
  font-weight: 600;
}
.fnw-700 {
  font-weight: 700;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}
.t-capital {
  text-transform: capitalize;
}

.l-height1 {
  line-height: 1;
}
.p-relative {
  position: relative;
}

.o-hidden {
  overflow: hidden;
}

.o-auto {
  overflow: auto;
}
.o-y-auto {
  overflow-y: auto;
}
.o-x-hidden {
  overflow-x: hidden;
}

.o-y-hidden {
  overflow-y: hidden;
}
.o-wrap-any {
  overflow-wrap: anywhere;
}

.o-fit-contain {
  object-fit: contain;
}

.o-fit-cover {
  object-fit: cover;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nb-proxy-button {
  display: flex;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  align-items: center;
}

.nb-nice-scroll-style {
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #ccc;
    background: #f7fdfb;
    position: relative;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f7fdfb;
    border-radius: 0;
    background: #f7fdfb;
  }
}

.nb-list-commas {
  > * {
    &::after {
      content: ",";
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.nb-text-primary-hover {
  &:hover {
    color: $primary-color;
  }
}

.nb-bg-primary-44 {
  // background-color: $primary-color-44;
}
.text-color-disabled {
  color: $disabled-text-color;
}

.post-reaction-button {
  display: flex;
  > div {
    display: flex;
    align-items: center;
  }
}

.post-like-button:hover {
  .post-reacts-emojis {
    pointer-events: all;
    transform: translate(0px, -40%);
    transition: 0.4s all cubic-bezier(0.69, 0.03, 0.2, 0.96);
    transition-delay: 0.7s;
    opacity: 1;
  }
}

.post-like-button {
  position: relative;
  svg {
    height: 21px;
    width: 21px;
  }
}

.post-like-button > span:last-child {
  margin: 0px 0px 4px 10px;
}

.post-like-button .post-reacts-emojis {
  pointer-events: none;
  display: flex;
  position: absolute;
  top: -100%;
  left: 0;
  transform: translate(0px, 100px);
  opacity: 0;
  background: #fff;
  min-height: 50px;
  border-radius: 3rem;
  align-items: center;
  padding: 0 1rem;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.2);
  > svg {
    height: 30px;
    width: 30px;
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
}
.post-reacts-emoji {
  position: relative;
  svg {
    height: 21px;
    width: 21px;
  }
}

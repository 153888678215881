.nb-search-input {
  // max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  > span {
    min-height: 44px;
    border-radius: 5px;
  }
  .ant-input-affix-wrapper {
    padding-right: 4px;
  }
  .nb-search-input-suffix {
    line-height: 0;
    background: #bac8ca;
    display: flex;
    height: 100%;
    min-width: 35px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    svg {
      height: 11px;
      width: 11px;
      g,
      line {
        stroke: #ffffff;
      }
    }
  }
}

@import "./../../../scss/tools/mixins";
@import "./../../../scss/settings/global";
@import "./../../../scss/settings/colors";

.post-comment {
  .post-comment-right {
    .post-comment-right-comment-content {
      background: $comment-bg-color;
      border-radius: $base-border-radius;
      color: $black-color;
      span {
        color: $primary-color;
      }
    }
  }
  .replies {
    .post-comment {
      &:last-child {
        .post-comment-right {
          > button {
            display: flex;
          }
        }
      }
      .post-comment-right {
        > button {
          display: none;
        }
      }
    }
  }
}

@import "./../../../../scss/settings/global";
@import "./../../../../scss/settings/colors";

.post-actions-save-buttons {
  // border-radius: $base-border-radius * 0.5 !important;
  // border: 1px solid $border-color !important;
  // padding: 0.25rem 0.3rem !important;
  // line-height: 1 !important;
  // max-height: 20px !important;
  border-radius: none !important;
  border: none !important;
  padding: 0rem 0.3rem 1rem !important;
  line-height: 1 !important;
  max-height: 20px !important;
  .ant-btn {
    border: none !important;
    border-color: transparent !important;
  }
  .fill path{
    fill: $darkBlue;
  }
}

.branch-card-skeleton {
  padding: 1rem;
  text-align: center;
  .branch-card-skeleton-avatar {
    height: 6rem;
    width: 6rem;
  }
  .branch-card-skeleton-paragraph {
    .ant-skeleton-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .ant-skeleton-paragraph {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0rem;
    }
  }
}

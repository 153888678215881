.update-post-modal {
  width: 650px !important;
  overflow: hidden;
  margin-top: 2em;

  .update-post-modal-content {
    position: relative;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}

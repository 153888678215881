@import "../../scss/tools/mixins";
@import "../../scss/settings/_colors.scss";

.stuffBtn {
  background-color: $white-color !important;
  color: #000 !important;
  border-color: #fffcfb !important;
  border-radius: 10px !important;
  min-height: 60px !important;
}
.keepSign {
  display: flex;
  justify-content: space-between;
}
.login {
  .login-title {
    font-size: 1.8rem;
    margin-bottom: 0px;
  }
  a.login-link {
    font-size: 18px;
  }
}
.ant-layout-footer {
  .onboarding-layout-footer-right {
    justify-content: center;
    padding: 15px 0px;
  }
}
.login-form {
  .nb-button {
    height: 55px;
  }
}

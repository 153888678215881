.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.75rem 1.75rem;
  // border-top: 1px solid $catskillWhite;
  width: 100%;
  justify-content: space-between;

  @include xs {
    flex-direction: column;

    .next-btn-container {
      .btn {
        min-width: 5rem;
      }
    }
  }

  .rmp-check-container {
    max-width: 100%;
    display: flex;
    align-items: center;
    // .rmp-check {
    //   position: relative;
    //   top: -4px;
    // }
    @include xs {
      margin-bottom: 0.75rem;
    }

    .rmp-label-container {
      padding-left: 1.5rem;

      .rmp-label {
        @include xs {
          font-size: 0.9rem;
        }

        .rmp-label-primary {
          margin-right: 0.75rem;

          @include xs {
            display: block;
          }
        }
      }
    }
  }
}

.prev-btn-container {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;

  &:focus,
  &:active,
  &:hover {
    background: none;
  }

  .prev-btn-icon {
    width: 1.65rem;
    height: 1.65rem;
  }

  .rmp-label-container {
    padding-left: 1rem !important;

    .prev-btn-text {
      // color: $cadetBlue;
      font-size: 0.9rem;
    }
  }
}

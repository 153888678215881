.post-parent {
  display: flex;
  align-items: center;
  border: 2px solid $grey2;
  // padding: 1.25rem 1.25rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: $white-color;
  box-shadow: 0px 6px 80px 0px rgba(45, 45, 46, 0.15);
  position: relative;
  margin-bottom: 0.75rem;
  padding: 1rem;
  @include xs {
    flex-direction: column;
    padding: 0.5rem 0;
  }

  .avatar-container {
    flex: 1;
    max-width: 9%;

    @include xs {
      max-width: 100%;
    }

    @include sm {
      max-width: 10%;
    }
  }

  .title-container {
    flex: 2;
    max-width: 91%;
    // background-color: $catskillWhite-opac50;
    padding: 0.6rem 1.5rem;
    border-radius: 0.5rem;

    @include xs {
      width: 100%;
      margin-top: 0.5rem;
    }

    @include sm {
      width: 95%;
    }

    .title {
      margin: 0;
      padding: 0;
      font-size: 1.1rem;
      color: $grayishBlack;

      @include xs {
        font-size: 0.85rem;
        text-align: center;
      }
    }
  }
}
.custom-input-container {
  border-top: 1px solid $grey2 !important;

  border-bottom: 1px solid $grey2 !important ;
}

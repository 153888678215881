@import "./../../../../scss/settings/colors";

.nb-more-button {
  background: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  &:hover {
    border: 2px solid $border-color;
  }
}

.nb-listing-layout-tabs-pane {
  .nb-simple-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 5px;
    .post-description {
      p {
        color: #000000;
      }
    }

    .global-post-wrapper {
    }
  }
}
.global-search-wrapper {
  min-height: calc(100vh - 170px);
}
.global-search-loader {
  min-height: calc(100vh - 170px);
  display: flex;
  justify-content: center;
  align-items: center;
}

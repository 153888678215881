.btn {
  font-size: 0.8rem;
  border-radius: 0.5rem !important;
  // min-width: 80px;
  outline: none;
  box-shadow: none;
  background-color: $primary-color-2;
  color: $white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not([disabled]):hover,
  &:not([disabled]):active,
  &:not([disabled]):focus {
    background: $primary-color-2 !important;
    color: $white !important;

    .btn-icon {
      fill: white;
      path {
        fill: white;
      }
    }
  }
  &-primary {
    background-color: $primary-color-2;
    color: $white;
  }
  &-secondary {
    background-color: $primary-color-2;
    // color: $HunterGreen;
  }
  &-disabled {
    // background-color: $lightGrey1 !important;
    color: $white !important;
    cursor: not-allowed;
    &:hover {
      // background-color: $lightGrey1 !important;
    }
  }
  &-transparent {
    background-color: transparent !important;
    // color: $HunterGreen !important;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      color: $white;
    }
  }
  &-outlined {
    background-color: $white;
    color: $grayishBlack;
    font-weight: bold;
    border: 2px solid $primary-color-2;
    &-icon {
      color: $primary-color-2;
    }
    &:hover {
      color: $white;
      &-outlined-icon {
        color: $white;
      }
    }
    &:active,
    &:focus {
      color: $grayishBlack;
      background-color: $white !important;
      &-outlined-icon {
        color: $primary-color-2;
      }
    }
  }
  &-load-more {
    border-radius: 0.15rem !important;
    color: $grayishBlack;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      border-radius: 0.15rem !important;
      color: $primary-color-2;
    }
    &:active,
    &:focus {
      border-radius: 0.15rem !important;
      color: $primary-color-2;
    }
  }
  &-danger {
    background-color: $danger;
    color: $white;

    &:not([disabled]):hover,
    &:not([disabled]):active,
    &:not([disabled]):focus {
      // background-color: $danger-hover !important;
      color: $white;
    }
  }
  .btn-icon {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
  }
  @include xs {
    min-width: fit-content;
  }
  @include sm {
    min-width: fit-content;
  }
}

@import "./../../../../scss/settings/colors";

.nb-divider {
  border-bottom: 1px solid $border-color;
  &.nb-divider-with-style {
    margin: 1rem 0;
  }
  &-primary {
    border-bottom: 1px solid $primary-color;
  }
}

.posting-as-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 1.25rem 1.75rem 1.25rem 1.75rem;
  padding: 1.25rem 0.15rem 1.25rem 0.15rem;
  // @extend .apply-bottom-border;

  @include xs {
    flex-direction: column;
  }

  .posting-as-text {
    margin: 0;
    padding: 0;
    // color: $cadetBlue;
    flex: 1;

    &-primary {
      margin: 0 0.35rem;
    }
  }

  .profile-switch-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    background-color: $bg-light-grey;
    max-width: 35%;
    padding: 0.25rem 0.75rem;
    // border: 1px solid $catskillWhite;
    border-radius: 0.5rem;

    @include xs {
      margin-top: 1rem;
      min-width: 75%;
    }

    .ant-select {
      display: flex;
      align-items: center;
      flex: 1;
      width: 100%;

      .ant-select-selector {
        padding: 0;

        .ant-select-selection-item {
          margin-right: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .ant-select-arrow {
        right: 0;
      }
    }
  }
}

.custom-tag {
  font-size: 0.75rem;
  border-radius: 0.35rem;
  width: fit-content;
  outline: none;
  box-shadow: none;
  background-color: $primary-color-2;
  color: $white;
  border: none;
  padding: 0.05rem 0.5rem;
}

$lightBlue: #6f88b8;
$darkBlue: #3155a6;
// $apple-44: #c6e5c6;
$white: #ffffff;
$black: #000000;
$grayishBlack: #2c2c2c;
$grey: #efefef;
$grey2: #dcddde;
$grey3: #949599;
// $mariner: #1e6bd5;
// $caribbeanGreen: #f7fdfb;
// $catskillWhite: #e6ebf2;
// $cadetBlue: #8189a9;
$theme: #6f88b8;
// $apple-light: #40be3338;
// $polar: #f0fcf8;
// $HunterGreen: #142412;
// $whisper: #f7f8fb;
// $lightGrey: #f8fafc;
// $boxShadow: #80808029;
// $catskillWhite-opac50: #e6ebf250;
// $apple-opac50: #41a03750;
// $lightGrey1: #aab7c4;
// $apple-light-bg: #e9f2e6;
$danger: #ff4d4f;
// $danger-hover: #e42929;
// $lightGrey2: #b8c3cd;
// $darkGrey: #3a444e;

.location-map-container {
  width: 100%;
  height: 15rem;

  .marker-text {
    color: $grayishBlack;
    font-weight: bold;
    background-color: $white;
    padding: 0.5rem 0.75rem;
    width: fit-content;
    text-align: center;
    position: relative;
    border-radius: 0.5rem;

    .marker-pointer {
      width: 2rem;
      fill: rgb(202, 0, 0);
      position: absolute;
      top: -1.5rem;
      left: -0.25rem;

      path {
        fill: rgb(202, 0, 0);
      }
    }
  }
}

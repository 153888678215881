@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "../../scss/settings/variable";
@import "../../scss//settings/colors";

$base-font-size: 14px;
$base-line-height: 1.5715;
$base-border-radius: 0.57rem;
$base-border-radius-sm: calc(0.57rem / 2);

body {
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 20px;
}
h1 {
  font-size: 3.5rem;
  @media (max-width: 1100px) {
    font-size: 2rem;
  }
}
h2 {
  font-size: 3rem;
  @media (max-width: 1100px) {
    font-size: 1.8rem;
  }
}
h3 {
  font-size: 2.5rem;
  @media (max-width: 1100px) {
    font-size: 1.5rem;
  }
}
h4 {
  font-size: 1.3rem;
  @media (max-width: 1100px) {
    font-size: 1.2rem;
  }
}
h5 {
  font-size: 1.2rem;
  @media (max-width: 1100px) {
    font-size: 1.1rem;
  }
}
h6 {
  font-size: 1.1rem;
  @media (max-width: 1100px) {
    font-size: 1rem;
  }
}
.ant-input-lg {
  padding: 6.5px 20px;
}
.sub-title {
  font-size: 1.8rem;
}
.m0 {
  margin: 0px;
}

.ant-spin-dot-item {
  background: $darkBlue !important;
}

.ant-form-item-label {
  > label {
    color: #aab7c4;
    font-size: 0.8rem !important;
    &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
      margin-left: 0.2rem;
    }
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.success-pop-width {
  width: 1000px;
}

.gradient-background {
  // background: transparent linear-gradient(180deg, #e7e6ff 0%, #e7e6ff 100%) 0%
  //   0% no-repeat padding-box;
  background: url("../../assets/images/landingPage/banner.webp");

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #120641;
  }
}

.ant-upload-list-item-card-actions-btn {
  .anticon-delete {
    path {
      fill: red;
    }
  }
}

.ant-dropdown-menu-item:hover {
  color: white !important;
  background: $primary-color-2 !important;

  button {
    color: white !important;
  }
}
.notification-container {
  .ant-dropdown-menu-item:hover {
    color: white !important;
    background: $grey !important;

    button {
      color: white !important;
    }
  }
}

.rc-virtual-list-holder-inner {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: white;
    background-color: $theme;
  }
}

// @media (min-width: 1600px) {
//   .home-container {
//     .ant-col-xxl-16 {
//       flex: 0 0 100%;
//     }
//   }
// }
.nb-full-screen {
  height: 100%;
}
.nb-default-icon {
  font-size: 1.5rem;
  color: $darkBlue;
  padding: 0px 5px;
}
.global-shadow-background-page {
  background-image: url(../../assets/images/bottomshade.svg),
    url(../../assets/images/topshade.svg);
  background-position:
    left bottom,
    right top;
  background-repeat: no-repeat;
  background-size: 500px;
}

.btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  /* background-color: rgba(0, 0, 0, 0.04); */
  background-color: $primary-color !important;
  box-shadow: none;
}
.ant-btn-default:hover {
  color: $darkBlue !important;
  border-color: $darkBlue !important;
}
.ant-btn-primary {
  background-color: $primary-color !important;
  border: 2px solid $primary-color !important;
}
.ant-btn-primary:hover {
  background-color: $primary-color-2 !important;
  border: 2px solid $primary-color-2 !important;
}
.nb-listing-layout-main-content {
  background-image: url(../../assets/images/bodybg.svg);
  background-position: center;
  background-size: 100%;
  height: 100%;
  @media screen and (max-width: 1200px) {
    padding: 0px 10px;
  }
}
.heading-para {
  p {
    color: black;
  }
}
.ant-avatar-circle {
  //box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
  border: 2px solid $primary-color-2;
  background: $lightBlue !important;
  color: $white;
  min-width: 40px;
}
.ant-input-affix-wrapper {
  &:hover {
    border-color: $darkBlue !important;
  }
}
.ant-input-affix-wrapper-focused {
  border-color: $darkBlue !important;
}
.ant-radio.ant-radio-checked {
  .ant-radio-inner {
    background-color: $white;
    border-color: $darkBlue;
  }
  .ant-radio-inner::after {
    background-color: $darkBlue;
  }
}
.ant-switch.ant-switch-checked {
  background: $darkBlue;
  .ant-switch-handle::before {
    background-color: $white !important;
  }
}
.ant-switch {
  background: $grey;
  .ant-switch-handle::before {
    background-color: $primary-color;
  }
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: $grey2;
  .ant-switch-handle::before {
    background-color: $darkBlue;
  }
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: $primary-color;
}

.ant-checkbox-wrapper {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $darkBlue;
    border-color: $darkBlue;
  }
  .ant-checkbox-checked:after,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $darkBlue;
    background-color: transparent;
  }
}
.ant-checkbox-wrapper:hover {
  .ant-checkbox-checked:after {
    border-color: $primary-color !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color !important;
    border-color: $primary-color;
  }
}
// .profile-tabs-btn {
//   button {
//     &.active {
//       background-color: $primary-color !important;
//       color: $white !important;
//       border: none !important;
//     }
//   }
// }
.post-comment-form {
  .ant-upload {
    &.ant-upload-select {
      display: none;
    }
  }
}

.nb-listing-layout-tabs {
  .ant-tabs-tab {
    color: $primary-color-2 !important;
  }
  .ant-tabs-tab-active {
    &:hover {
      color: $primary-color-2 !important;
    }
    .ant-tabs-tab-btn {
      color: $primary-color-2 !important;
    }
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid $primary-color-2 !important;
  }
}

.notification-dropdown {
  .ant-badge-count {
    font-size: 10px !important;
  }
}

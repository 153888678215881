.align-items-flex-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end;
}
.flex {
  display: inline;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column;
}
.justify-content-space-start {
  justify-content: flex-start;
}
.justify-content-space-center {
  justify-content: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-flex-end {
  justify-content: flex-end !important;
}
.justify-content-space-baseline {
  justify-content: baseline;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;

  &-no-wrap {
    flex-wrap: nowrap;
  }
}

.bg-light-grey {
  // background-color: $whisper;
}

.text-primary {
  color: $primary-color-2;
}

.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}
.h-75 {
  height: 75%;
}
.h-50 {
  height: 50%;
}
.h-25 {
  height: 25%;
}

.rotate-45 {
  transform: rotate(45deg);
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-45-neg {
  transform: rotate(-45deg);
}
.rotate-90-neg {
  transform: rotate(-90deg);
}

.cursor-pointer {
  cursor: pointer;
}

.fill-primary {
  fill: $primary-color-2;

  path {
    fill: $primary-color-2;
  }
}

.border-bottom-none {
  border-bottom: none !important;
}

.b-none {
  border: none !important;
}

.apply-bottom-border {
  border-bottom: 1px solid $border-color;
}
.apply-bottom-border-grey {
  border-bottom: 1px solid $grey2;
}

.apply-top-border {
  border-top: 1px solid $border-color;
}

.fade-enter .container {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .container {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .container {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .container {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .container,
.fade-exit-active .container {
  transition:
    opacity 500ms,
    transform 300ms;
}

.fix-bottom {
  position: absolute;
  bottom: 0;
}

.tt {
  &-capitalize {
    text-transform: capitalize;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}

.v-align-top {
  vertical-align: top;
}

.fs-italic {
  font-style: italic;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-end {
  justify-self: flex-end;
}

.margin-0 {
  margin: 0;
}

.mx-auto {
  margin: 0 auto;
}

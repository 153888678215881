@import "../../../scss/settings/colors";

.post-url-container {
  display: flex;
  color: $black-color;
  background-color: $white;
  .post-url-image {
    display: none;
    max-width: 128px;
    object-fit: contain;
    @media screen and (min-width: 700px) {
      display: flex;
    }
  }
  .post-url-image-responsive {
    object-fit: cover;
    width: 100%;
    height: 80px;
    margin-bottom: 5px;
    @media screen and (min-width: 700px) {
      display: none;
    }
  }
  .post-url-info {
    padding: 1em 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-wrap: anywhere;
    .post-url-link-info {
      text-decoration: underline;
      color: blue;
    }
    p {
      max-height: 75px;
      overflow: auto;
      @media screen and (min-width: 700px) {
        max-height: none;
      }
    }
    @media screen and (min-width: 700px) {
      padding: 1.5em;
    }
  }
  &:hover {
    color: $black-color;
    background-color: $grey;
  }
}

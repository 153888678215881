.listing-card-skeleton {
  padding: 0;
  overflow: hidden;
  .ant-skeleton.ant-skeleton-element {
    width: 100%;
    height: 200px;
    .ant-skeleton-avatar-square {
      width: 100%;
      height: 100%;
    }
  }
}

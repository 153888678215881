@import "./../../../../scss/tools/mixins";
@import "./../../../../scss/settings/global";
@import "./../../../../scss/settings/colors";

.nb-simple-card {
  background: $white-color;
  // border: 1px solid $border-color;
  // border-radius: $base-border-radius;

  &.nb-simple-card-primary-border {
    border: 2.5px solid $primary-color;
  }
}

.nb-heading-info {
  display: flex;
  .nb-heading-info-content {
    .nb-heading-info-content-links {
      display: flex;
      justify-content: space-between;
      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.nb-sider-right {
  h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
}

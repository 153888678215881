@import "./../../../scss/tools/mixins";
@import "./../../../scss/settings/global";
@import "./../../../scss/settings/colors";

.message-post {
  border-radius: 4px;
  border: 1px solid $grey2;
  background: $white-color;
  box-shadow: 0px 6px 80px 0px rgba(45, 45, 46, 0.15);
  .nb-faded-text {
    color: #949599 !important;
  }
  .nb-more-button {
    :hover {
      border: none !important;
    }
  }
}

.nb-post-notification {
  .ant-notification-notice-close {
    right: 16px !important;
  }
}

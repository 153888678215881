@import "../../../../scss/tools/mixins";
@import "../../../../scss/settings/global";
@import "../../../../scss/settings/_colors.scss";

.nb-listing-layout {
  &.ant-layout {
    min-height: 100% !important;
    background: transparent !important;
    display: flex;
    flex-direction: row;
    .ant-layout-sider,
    section.ant-layout {
      background: transparent;
    }
    .global-shadow-background-page {
      background-image: url(../../../../assets/images/bottomshade.svg),
        url(../../../../assets/images/topshade.svg);
      background-position:
        left bottom,
        right top;
      background-repeat: no-repeat;
      background-size: 500px;
      //box-shadow: 0 3px 10px grey;
      margin-bottom: 7px !important;
      // padding-bottom: 45px;
    }
    .nb-listing-layout-body {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      @include xxxl {
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
      }
      @include xxl {
        width: 100%;
        margin: 0 auto;
        padding: 0 !important;
      }
      main.ant-layout-content {
        padding: 0rem;
        @include sm-max-below {
          padding: 0;
        }
      }
      aside.ant-layout-sider {
        @include sm-max-below {
          display: none;
        }
        &.nb-listing-layout-body-left {
          .ant-layout-sider-children {
          }
          @media (max-width: 1024px) {
          }
        }

        &.nb-listing-layout-body-right {
          flex: 0 0 320px !important;
          min-width: 320px !important;

          @media (max-width: 1024px) {
            display: none;
          }
          @media (max-width: 1200px) {
            flex: 0 0 120px !important;
            min-width: 120px !important;
          }
        }
      }
    }
  }
}
.ant-layout-sider {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 30px 0px 0px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  background-color: white !important;
  @media screen and (max-width: 1600px) {
    position: fixed !important;
    z-index: 9999999 !important;
    height: 100vh;
    background-color: white !important;
  }
}
.ant-layout-sider-children {
  position: fixed;
  width: inherit;
  @media screen and (max-width: 992px) {
    // position: absolute;
    // background-color: white;
    // z-index: 9999;
    position: relative;
  }
}

.nb-vertical-side-menu {
  .nb-vertical-side-menu-item {
    margin: 10px 16px;
  }
  &.home {
    .nb-base-text {
      svg {
        background-color: $grey !important;
        path {
          stroke: $primary-color-2 !important;
        }
      }
    }
    a {
      background-color: $grey !important;
      border-radius: 0.57rem;
      color: $primary-color-2;
      font-weight: bold;
    }
  }
}
.ant-layout-sider-zero-width-trigger {
  svg {
    // display: none !important;
    path {
      display: none;
    }
  }
  .anticon {
    background-image: url(../../../icons/sidecloseicon.png) !important;
    background-position: center !important;
  }
}
.ant-layout-sider-zero-width-trigger {
  background-color: white !important;
  box-shadow: 0 3px 10px $grey2;
  right: -27px !important;
  border-radius: 50px !important;
  width: 29px !important;
  height: 29px !important;
  color: #fff;
  font-size: 18px;
  line-height: 32px !important;
}

.custom-sider .ant-layout-sider-trigger {
  display: block !important;
  background-color: red;
}
